import { memo } from 'react';

import ModalSidebar from '@Modals/_components/ModalSidebar';
import CategoriesGroup from './elems/CategoriesGroup';
import styles from './SEOCategories.module.css';

import type { FC } from 'react';
import type { ModalSidebarProps } from '@Modals/_components/ModalSidebar';
import type { SEOCategory } from '@Types/Category';

export interface SEOCategoriesModalData {
  seoCategories: SEOCategory;
}

export type SEOCategoriesProps = ModalSidebarProps<SEOCategoriesModalData>;

const SEOCategories: FC<SEOCategoriesProps> = (props) => {
  const { modal, ...restProps } = props;
  const { title = '', groups = [] } = modal.data.seoCategories || {};

  return (
    <ModalSidebar
      {...restProps}
      title={title}
      view='default'
      modal={modal}
      cnWrapperContent={styles.wrapperContent}
    >
      {groups.map((group) => (
        <CategoriesGroup className={styles.group} key={group.name} group={group} />
      ))}
    </ModalSidebar>
  );
};

export default memo(SEOCategories);
