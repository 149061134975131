import { memo } from 'react';

import Link from '@Navigation/Link';
import styles from './CategoriesGroup.module.css';

import type { FC, HTMLAttributes } from 'react';
import type { CategoriesGroupData } from '@Types/Category';

export interface RegionPositionProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  group: CategoriesGroupData;
}

const CategoriesGroup: FC<RegionPositionProps> = (props) => {
  const { group, ...restProps } = props;

  return (
    <div {...restProps}>
      <div className={styles.title}>{group.name}</div>
      <div>
        {group.categories.map((category) => (
          <Link className={styles.link} view='primary' key={category.name} to={category.link}>
            {category.name}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default memo(CategoriesGroup);
